<!--
 * @Descripttion: 说明
 * @Author: liuzhixiang
 * @Date: 2021-07-14 10:03:21
 * @LastEditors: liuzhixiang
 * @LastEditTime: 2021-07-14 11:31:21
-->

<template>
  <div class="confirmOrder" :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="确认订单"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <div>
      <!-- 用户收货地址 -->
      <div class="details_address">
        <div class="address_icon">
          <!-- <img
            src="../../../assets/ShopImg/deizhi.png"
            alt=""
            style="width: 100%; height: 100%"
          /> -->
          <van-icon name="location" size="18" color="#ffffff" />
        </div>
        <div class="address_text" v-if="pickUpMethod == 0">
          <div v-if="address.ContactName">
            <span>{{ address.ContactName }}</span
            ><span>{{ address.ContactPhone }}</span>
          </div>
          <div v-if="address.Address">
            {{
              address.Province + address.City + address.Area + address.Address
            }}
          </div>
          <div v-if="!address.ContactName">请添加收货信息</div>
        </div>
        <div class="address_text" v-if="pickUpMethod == 1">
          {{ selfAddress }}
        </div>
        <!-- <div class="address_edit">{{address.ContactName?"修改":"添加"}}</div> -->
        <div
          class="address_edit"
          v-if="pickUpMethod == 0"
          @click="selectAddress"
        >
          选择
        </div>
      </div>
      <div style="height: 0.625rem; background-color: #f5f5f5"></div>

      <!-- 商品信息 -->
      <div
        class="order_info"
        v-for="(item, i) in settlement.GoodsDetailList"
        :key="i"
      >
        <div class="info_detail">
          <img :src="item.CoverImg" alt="" />
          <div class="detail_content">
            <div>
              {{
                item.GoodsName.length > 30
                  ? item.GoodsName.substr(0, 30) + "..."
                  : item.GoodsName
              }}
            </div>
            <div>
              <span>{{ item.SpecName }}</span>
            </div>
            <div>
              <span v-if="item.Points > 0"
                >{{ item.Points / item.Count }}积分+</span
              >
              ￥<span>{{
                Math.round((item.Price / item.Count) * 100) / 100
              }}</span>
            </div>
            <!-- Math.round(item.Price/item.Count*100)/100 -->
          </div>
        </div>
        <!-- 数量 -->
        <div class="info_num">x{{ item.Count }}</div>
      </div>

      <!-- 订单信息 -->
      <van-cell-group>
        <van-cell is-link>
          <template #title>
            <div class="cell_title">
              <span>配送方式</span>
              <span>快递/自提</span>
            </div>
          </template>
          <template #default>
            <div class="cell_right" @click="showPickUp = true">
              <span>{{ pickUpMethod == 0 ? "快递" : "自提" }}</span>
              <span>￥{{ postageMoney }}</span>
            </div>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <div class="cell_title">
              <span>数量</span>
            </div>
          </template>
          <template #default>
            <div>
              <span class="c_333333">×{{ settlement.TotalCount }}</span>
            </div>
          </template>
        </van-cell>
        <van-cell is-link>
          <template #title>
            <div class="cell_title">
              <span>优惠券</span>
            </div>
          </template>
          <template #default>
            <div class="cell_right" @click="showCoupon = true">
              <span style="color: #fe4a4a"
                >-￥{{ settlement.DiscountMoney }}</span
              >
            </div>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <div class="cell_title">
              <span>支付方式</span>
              <!-- <span>普利点/支付宝/微信</span> -->
            </div>
          </template>
          <!-- <template #default>
            <div class="cell_right" @click="showPay = true">
              <span>{{
                payMethod == 0 ? "普利点" : payMethod == 1 ? "支付宝" : "微信"
              }}</span>
            </div>
          </template> -->
        </van-cell>
        <!-- 支付方式 -->
        <van-radio-group v-model="payMethod">
          <van-cell-group>
            <van-cell
              clickable
              v-for="p in pays"
              :key="p.value"
              @click="payMethod = p.value"
            >
              <template #title>
                <div class="cell_title">
                  <img
                    :src="p.img"
                    alt=""
                    style="width: 24px; height: 24px; margin-right: 10px"
                  />{{ p.lable }}
                </div>
              </template>
              <template #right-icon>
                <van-radio :name="p.value" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-cell>
          <template #title>
            <div class="cell_title">
              <span>订单备注</span>
              <van-field
                class="remarks"
                v-model="remark"
                name="pattern"
                placeholder="选填"
              />
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <div class="total">
        <div>
          共计{{ settlement.TotalCount }}件
          <span>合计：</span>
          <span v-if="settlement.TotalPoints > 0"
            >{{ settlement.TotalPoints }}积分+</span
          >
          <span>￥</span><span>{{ payMoney }}</span>
        </div>
      </div>
    </div>
    <div style="height: 50px"></div>
    <!-- 提交订单 -->
    <van-submit-bar button-text="提交订单" @submit="onSubmit">
      <template #default>
        <div class="c_FE4A4A">
          <span v-if="settlement.TotalPoints > 0"
            >{{ settlement.TotalPoints }}积分+</span
          ><span class="fs_12">￥</span
          ><span class="fs_17 fw_bole">{{ payMoney }}</span>
        </div>
      </template>
    </van-submit-bar>
    <!-- 遮罩层 -->
    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="close">
            <van-icon name="clear" size="22" color="#D6D5D5" @click="close" />
          </div>
          <div class="title">需支普利点</div>
          <div class="number">{{ payMoney }}</div>
          <div class="btn" @click="confirm">确认</div>
        </div>
      </div>
    </van-overlay>
    <van-popup v-model="showPickUp" round position="bottom">
      <van-picker
        show-toolbar
        title="配送方式"
        :default-index="pickUpMethod"
        :columns="pickUpMethodList"
        @cancel="showPickUp = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <van-popup v-model="showPay" round position="bottom">
      <van-picker
        show-toolbar
        title="支付方式"
        :default-index="payMethod"
        :columns="payMethodList"
        @cancel="showPay = false"
        @confirm="onConfirmPay"
      />
    </van-popup>
    <van-popup
      v-model="showCoupon"
      round
      closeable
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div class="pop">
        <div class="pop_title">优惠</div>

        <div class="yh">
          <div
            class="yh_content"
            v-for="(item, i) in settlement.CouponList"
            :key="i"
            @click="selected(item)"
          >
            <div class="yh_content_money">
              <p>
                <span style="fontsize: 11px">￥</span>{{ item.DiscountMoney }}
              </p>
              <p>满{{ item.FullMoney }}元可用</p>
            </div>
            <div class="yh_content_detail">
              <p>{{ item.Title }}</p>
              <p>有效期至{{ item.EndTime.split(" ")[0] }}</p>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  shopSettlement,
  queryUserAddress,
  addShopOrder,
  queryHelpCenter,
} from "@/api/shop";
import navBar from "../components/navBar.vue";
import { Toast } from "vant";
export default {
  data() {
    return {
      value: 1,
      remark: "",
      show: false,
      showPay: false,
      showCoupon: false,
      address: {},
      goodsData: [],
      settlement: {},
      pickUpMethod: 0,
      postageMoney: 0,
      pickUpMethodList: ["快递", "自提"],
      payMethodList: ["普利点", "支付宝", "微信"],
      showPickUp: false,
      payMoney: 0,
      payMethod: "0",
      discountMoney: 0,
      orderInfo: {},
      pays: [
        {
          img: require("../../../assets/ShopImg/new_icon_pld.png"),
          value: "0",
          lable: "普利点支付",
        },
        {
          img: require("../../../assets/ShopImg/new_icon_alipay.png"),
          value: "1",
          lable: "支付宝",
        },
        {
          img: require("../../../assets/ShopImg/new_icon_wechat.png"),
          value: "2",
          lable: "微信",
        },
      ],
      radio: false,
      selfAddress: "",
      isWxApplets: true,
    };
  },
  components: {
    navBar,
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "确认订单";
      this.isWxApplets = false;
    }
  },
  mounted() {
    this.goodsData = JSON.parse(this.$route.query.goodsData);
    if (undefined != this.$route.query.addressData) {
      this.address = JSON.parse(this.$route.query.addressData);
    }
    console.log(this.address);
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.getQueryUserAddress();
      this.postShopSettlement();
      this.QueryHelpCenter();
    } else {
      this.userInfo.Id = 0;
    }
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    async QueryHelpCenter() {
      const res = await queryHelpCenter();
      this.selfAddress = res.response.Address;
    },
    selectAddress() {
      this.$router.push({
        path: "/shop/address/index",
        query: { goodsData: JSON.stringify(this.goodsData) },
      });
    },
    selected(item) {
      console.log(item);
      this.discountMoney = item.DiscountMoney;
      this.payMoney =
        this.settlement.TotalMoney + this.postageMoney - this.discountMoney;
      this.showCoupon = false;
    },
    onConfirm(value) {
      if (value == "自提") {
        this.pickUpMethod = 1;
        this.postageMoney = 0;
        //  this.payMoney = this.settlement.PayMoney - this.settlement.Postage;
        this.payMoney =
          this.settlement.TotalMoney + this.postageMoney - this.discountMoney;
      } else {
        this.pickUpMethod = 0;
        this.postageMoney = this.settlement.Postage;
        //  this.payMoney = this.settlement.PayMoney;
        this.payMoney =
          this.settlement.TotalMoney + this.postageMoney - this.discountMoney;
      }
      this.showPickUp = false;
    },
    onConfirmPay(value) {
      if (value == "普利点") {
        this.payMethod = 0;
      } else if (value == "支付宝") {
        this.payMethod = 1;
      } else if (value == "微信") {
        this.payMethod = 2;
      }
      this.showPay = false;
    },
    // 地址数据
    async getQueryUserAddress() {
      if (JSON.stringify(this.address) == "{}") {
        let parm = {};
        parm.pageIndex = 1;
        parm.pageSize = 1;
        parm.UserId = this.userInfo.Id;
        parm.IsDefault = 1;
        const res = await queryUserAddress(parm);
        this.address = res.response.data.length > 0 ? res.response.data[0] : {};
      }
    },
    // 结算
    async postShopSettlement() {
      let parm = {};
      parm.UserId = this.userInfo.Id;
      parm.SettlementDetail = this.goodsData;
      const res = await shopSettlement(parm);
      this.settlement = res.response;
      console.log(this.settlement, 999);
      this.postageMoney = this.settlement.Postage;
      this.payMoney = this.settlement.PayMoney;
      this.discountMoney = this.settlement.DiscountMoney;
    },
    async postAddShopOrder() {
      // console.log(1);
      let parm = {};
      parm.UserId = this.userInfo.Id;
      parm.UserName = this.userInfo.RealName;
      parm.SettlementDetail = this.goodsData;
      parm.Remark = this.remark;
      parm.PickUpMethod = this.pickUpMethod;
      parm.PayMethod = this.payMethod;
      parm.OrderSource = "H5";
      parm.ContactName = this.address.ContactName;
      parm.ContactPhone = this.address.ContactPhone;
      if (parm.PickUpMethod == 0) {
        parm.Address =
          this.address.Province +
          this.address.City +
          this.address.Area +
          this.address.Address;
      } else {
        parm.Address = this.selfAddress;
      }
      if (this.settlement.CouponList.length > 0) {
        parm.CouponId = this.settlement.CouponList[0].Id;
      }
      if (!parm.ContactName && parm.PickUpMethod == 0) {
        Toast.fail({
          message: "请添加收货信息",
        });
        return false;
      }
      const res = await addShopOrder(parm);

      if (res.success == true) {
        this.orderInfo = res.response;
        console.log(this.orderInfo);
        //微信支付
        if (this.payMethod == 2) {
          this.$router.push({
            path: "/shop/orders/detail",
            query: { orderId: res.response.Id },
          });
          window.open(res.response.PayData);
        } else if (this.payMethod == 1) {
          this.$router.push({
            path: "/shop/orders/detail",
            query: { orderId: res.response.Id },
          });
          //支付宝支付
          const div = document.createElement("div");
          div.id = "alipay";
          div.innerHTML = res.response.PayData;
          document.body.appendChild(div);
          document.querySelector("#alipay").children[0].submit(); // 执行后会唤起支付宝
        } else if (this.payMethod == 0) {
          //普利点支付
          Toast.success({
            message: res.msg,
          });
          this.$router.push({
            path: "/shop/orders/detail",
            query: { orderId: res.response.Id },
          });
        }
      } else {
        Toast.fail({
          message: res.msg,
        });
        this.show = false;
      }
    },
    confirm() {
      this.postAddShopOrder();
      //   this.close();
      // this.$router.push({
      //   path: "/shop/orders/payment",
      //   query: { orderid: this.artObj.StudentId }
      // });
    },
    close() {
      this.show = false;
    },
    onSubmit() {
      if (this.payMethod == 0) {
        this.show = true;
      } else {
        this.postAddShopOrder();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.confirmOrder {
  .details_address {
    min-height: 5.25rem;
    background: #fff;
    padding: 1rem 0.625rem 1rem 0.9375rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .address_icon {
      width: 2rem;
      height: 2rem;
      display: flex;
      flex: 0 0 2rem;
      align-items: center;
      justify-content: center;
      background-color: #000000;
      border-radius: 50%;
      margin-right: 10px;
    }
    .address_text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 0.8125rem;
      color: #999999;
      width: 100%;
      height: 100%;
      div:first-child {
        span:first-child {
          font-size: 0.9375rem;
          color: #333;
          font-weight: 700;
          margin-right: 0.5rem;
        }
      }
    }
    .address_edit {
      font-size: 0.875rem;
      color: #999999;
      width: 3.25rem;
      height: 1.625rem;
      line-height: 1.625rem;
      text-align: center;
      border-radius: 1.875rem;
      border: 1px solid #e8e8e8;
    }
  }
  .order_info {
    background: #fff;
    width: 100%;
    // height: 12.6rem;
    box-sizing: border-box;
    padding: 0.9375rem;
    position: relative;
    .info_detail {
      display: flex;
      margin-bottom: 0.9375rem;
      img {
        width: 5.5625rem;
        height: 5.5rem;
        border-radius: 0.25rem;
        margin-right: 0.9375rem;
      }
      .detail_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div:first-child {
          font-size: 0.875rem;
          color: #333333;
          font-weight: 700;
          margin-right: 1.25rem;
        }
        div:nth-child(2) {
          font-size: 0.75rem;
          color: #858585;
          display: flex;
          justify-content: space-between;
        }
        div:nth-child(3) {
          font-size: 0.75rem;
          color: #fe4a4a;
          span {
            color: #fe4a4a;
            font-size: 0.9375rem;
            font-weight: 700;
          }
        }
      }
    }
    .info_freight {
      display: flex;
      justify-content: flex-end;
      font-size: 0.875rem;
      color: #333333;
      margin-bottom: 1.125rem;
      div:first-child {
        margin-right: 6.18rem;
        span:first-child {
          margin-right: 0.625rem;
        }
        span:nth-child(2) {
          color: #d2d1d1;
        }
      }
      div:nth-child(2) {
        span:first-child {
          font-size: 0.5625rem;
        }
        span:nth-child(2) {
          font-size: 1rem;
          font-weight: 700;
        }
      }
    }
    .info_refund {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 0.75rem;
      div {
        width: 4.125rem;
        height: 1.75rem;
        border-radius: 1.875rem;
        border: 1px solid #e8e8e8;
        font-size: 0.75rem;
        color: #999;
        text-align: center;
        line-height: 1.75rem;
      }
    }
    .info_price {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 1rem;
      color: #333333;
      div:first-child {
        font-weight: 700;
      }
      div:nth-child(2) {
        color: #fe4a4a;
        span:first-child {
          font-size: 0.5625rem;
        }
        span:nth-child(2) {
          font-size: 1rem;
          font-weight: 700;
        }
      }
    }
    .info_num {
      position: absolute;
      right: 0.9375rem;
      top: 5.13rem;
    }
  }
  .cell_title {
    display: flex;
    .remarks {
      flex: 1;
      padding: 0;
      width: 85%;
    }
    span {
      &:nth-child(1) {
        display: inline-block;
        font-size: 14px;
        width: 3.5rem;
        margin-right: 0.75rem;
      }
      &:nth-child(2) {
        font-size: 14px;
        color: #d2d1d1;
      }
    }
  }
  .cell_right {
    span {
      &:nth-child(1) {
        display: inline-block;
        font-size: 14px;
        margin-right: 0.75rem;
        color: #666666;
      }
      &:nth-child(2) {
        font-size: 14px;
        color: #fe4a4a;
        font-weight: bold;
      }
    }
  }
  .total {
    background-color: #fff;
    padding: 0 0.9375rem;
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 0.0625rem solid #e8e8e8;
      height: 3.125rem;
      color: #999999;
      span {
        font-size: 14px;
        &:nth-child(1) {
          color: #333333;
          margin-left: 0.875rem;
        }
        &:nth-child(2) {
          font-size: 0.625rem;
          color: #fe4a4a;
        }
        &:nth-child(3) {
          font-size: 0.625rem;
          font-weight: bold;
          color: #fe4a4a;
        }
        &:nth-child(4) {
          font-size: 15px;
          font-weight: bold;
          color: #fe4a4a;
        }
      }
    }
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .block {
    width: 18.75rem;
    height: 12.5rem;
    padding: 0.9375rem;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    .close {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .title {
      font-size: 15px;
      color: #333333;
    }
    .number {
      font-size: 30px;
      font-weight: bold;
      margin: 0.75rem 0 0.9375rem;
    }
    .btn {
      width: 12.5rem;
      height: 2.625rem;
      font-size: 15px;
      color: #ffffff;
      background: #000;
      border-radius: 30px;
    }
  }
}
.van-cell-group {
  &::after {
    border: none;
  }
  .van-cell {
    &::after {
      border: none;
    }
  }
}
.van-overlay {
  z-index: 999;
}
.van-submit-bar__bar {
  justify-content: space-between;
}
.c_333333 {
  color: #333333;
}
.fw_bole {
  font-weight: bold;
}
.fs_12 {
  font-size: 12px;
}
.fs_17 {
  font-size: 17px;
}
.c_FE4A4A {
  color: #fe4a4a;
}

.yh {
  margin-top: 10px;
  padding-bottom: 15px;

  .yh_content {
    width: 100%;
    height: 100px;
    background-image: url("../../../assets/ShopImg/yh.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-shadow: 5px 4px 6px rgba(146, 146, 146, 0.16);
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .yh_content_money {
      width: 32.5%;
      text-align: center;

      p {
        color: #fff;
      }
    }

    .yh_content_money p:first-child {
      font-size: 22px;
    }

    .yh_content_money p:last-child {
      font-size: 10px;
    }

    .yh_content_detail {
      margin-left: 10px;
    }

    .yh_content_detail p:first-child {
      color: #333333;
      font-size: 14px;

      font-weight: bold;
    }

    .yh_content_detail p:last-child {
      font-size: 8px;
      color: #666666;
      margin-top: 12px;
    }

    .btns {
      width: 60px;
      height: 24px;
      background: #f95e2e;
      position: absolute;
      top: 57%;
      left: 80%;
      border-radius: 20px;
      font-size: 8px;
      color: #fff;
      line-height: 24px;
      text-align: center;
    }
  }

  .btns2 {
    width: 80%;
    margin: 0 auto;
  }
}

.ylq {
  position: absolute;
  width: 52px;
  height: 40px;
  right: 0;
  top: 0;
}

.pop {
  padding: 0 15px;

  .pop_title {
    color: #333333;
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    margin-top: 14px;
  }

  .yh {
    margin-top: 10px;
    padding-bottom: 15px;

    .yh_content {
      width: 100%;
      height: 100px;
      background-image: url("../../../assets/ShopImg/yh.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-shadow: 5px 4px 6px rgba(146, 146, 146, 0.16);
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .yh_content_money {
        width: 32.5%;
        text-align: center;

        p {
          color: #fff;
        }
      }

      .yh_content_money p:first-child {
        font-size: 22px;
      }

      .yh_content_money p:last-child {
        font-size: 10px;
      }

      .yh_content_detail {
        margin-left: 10px;
      }

      .yh_content_detail p:first-child {
        color: #333333;
        font-size: 14px;

        font-weight: bold;
      }

      .yh_content_detail p:last-child {
        font-size: 8px;
        color: #666666;
        margin-top: 12px;
      }

      .btns {
        width: 60px;
        height: 24px;
        background: #f95e2e;
        position: absolute;
        top: 57%;
        left: 80%;
        border-radius: 20px;
        font-size: 8px;
        color: #fff;
        line-height: 24px;
        text-align: center;
      }
    }

    .btns2 {
      width: 80%;
      margin: 0 auto;
    }
  }
}
/deep/ .van-submit-bar__button--danger {
  background: #000;
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
